import React, { useEffect, useState } from 'react';

import './XeoGLStats.scss';

export default function XeoGLStats () {
  const [xeoglStats, setXeoglStats] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!window.scene) {
      return ;
    }

    window.scene.on('tick', () => {
      const stats = window.xeogl.stats;
      setXeoglStats({...xeoglStats, ...stats});
    });

  }, [window.scene]);

  const handleToggleOpenStats = () => {
    setIsOpen(!isOpen);
  };

  const renderStats = () => {
    return Object.entries(xeoglStats).map(([category, properties]) => {
      const tableRows = Object.entries(properties).map(([key, val]) => {
        return (
          <tr key={key}>
            <td>{key}</td>
            <td>{val}</td>
          </tr>
        );
      });

      return (
        <div key={category}>
          <div className="category">{category}</div>
          <table className="stats-info">
            <tbody>
              {tableRows}
            </tbody>
          </table>
        </div>
      );
    })
  }

  const handleContainerClassName = isOpen ? ' stats-open' : '';
  return (
    <div className={"xeogl-stats-container position-absolute" + handleContainerClassName}>
      <div className="handle-container">
        <button className="handle" onClick={handleToggleOpenStats}>|||</button>
      </div>
      <div className="stats-container">
        { renderStats() }
      </div>
    </div>
  );
}
