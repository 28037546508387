import React from "react";
import { useDispatch } from "react-redux";
// reusable components import
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import Switch from "../../../components/Switch/Switch";
import { layoutActions } from "../../../redux/actionFunctions/layoutActions";
import * as roomFunctions from "./KitRoomFunctions";

const availableMeasurements = roomFunctions.availableMeasurements;

export default function KitRoomForm(props) {
  const dispatch = useDispatch();

  function issueWarningTrays({ callbackYes }) {
    props.openPopup({
      ...roomFunctions.trayWarnings.TRAY_WILL_RESET,
      content: roomFunctions.trayWarnings.TRAY_WILL_RESET.message,
      callbackYes,
      callbackNo: () => {},
      buttonOkText: null,
    });
  }
  function validateRoomDimensions(roomValue, measureSystem, type, ignoreEmpty) {
    if (!ignoreEmpty && !props.traysAreEmpty) {
      issueWarningTrays({
        callbackYes: (closePopup) => {
          validateRoomDimensions(roomValue, measureSystem, type, true);
          props.emptyTrays();
          closePopup();
        },
      });
      return;
    }
    roomValue = roomValue.replace(/,/g, "");
    roomValue = roomValue.replace(/[^0-9.]/g, "");
    if (roomValue.split(".").length - 1 > 1) {
      roomValue = roomValue.replace(/\.$/, "");
    } else if (roomValue.split(".").length - 1 == 1) {
      if (roomValue.split(".")[1].length > 1) {
        return false;
      }
    }

    if (type == "W") {
      props.setRoomSize({
        ...props.roomSize,
        roomWidthVal: roomValue,
      });
    } else {
      props.setRoomSize({
        ...props.roomSize,
        roomLengthVal: roomValue,
      });
    }
  }

  function validateStepBeforeContinue() {
    const error = roomFunctions.roomSquareAreaError(props.roomSize);
    if (
      props.roomSize.roomLengthVal != "" &&
      props.roomSize.roomWidthVal != "" &&
      !error
    ) {
      props.history.push({
        pathname: "/roombuilder/grow-area",
        search: props.history.location.search,
      });
    } else {
      let roomWidthValError = false;
      let roomLengthValError = false;

      if (props.roomSize.roomWidthVal == "") {
        roomWidthValError = true;
      }

      if (props.roomSize.roomLengthVal == "") {
        roomLengthValError = true;
      }

      if (error) {
        layoutActions(dispatch).pushPopupStack({
          key: Date.now(),
          title: "",
          buttonOkText: "Ok",
          content: {
            title: "LARGER ROOMS COMING SOON",
            text: error.message
          },
        });
      }
    }
  }

  const errorToShow = props.getStepError();
  const continueDisabled = errorToShow != null;

  return (
    <div className="step-content kit-room-content">
      <h1 className="step-title">
        WHAT IS YOUR
        <br /> ROOM SIZE?
      </h1>
      <Switch
        listValues={availableMeasurements}
        value={props.roomSize.measureSystem}
        onSwitch={(selectedValue) => {
          props.changeMeasureSystem(selectedValue);
        }}
        className="generic-box-shadow"
      />
      <div className="room-form">
        <div className="room-form-label">ROOM WIDTH</div>
        <Input
          type="text"
          placeholder=""
          value={props.roomSize.roomWidthVal || ""}
          onChange={(event) => {
            validateRoomDimensions(
              event.target.value,
              props.roomSize.measureSystem,
              "W"
            );
          }}
          min={0}
          className={`generic-box-shadow ${
            props.roomSize.roomWidthValError == true ? "input-error" : ""
          }`}
        />
        <div className="room-form-label">ROOM LENGTH</div>
        <Input
          type="text"
          placeholder=""
          value={props.roomSize.roomLengthVal || ""}
          onChange={(event) =>
            validateRoomDimensions(
              event.target.value,
              props.roomSize.measureSystem,
              "L"
            )
          }
          min={0}
          className={`generic-box-shadow ${
            props.roomSize.roomLengthValError == true ? "input-error" : ""
          }`}
        />
      </div>
      <div className="info-label" style={{ display: "none" }}>
        * Maximum dimension is 300 x 300 ft. For larger rooms
        <br /> we recommend duplicating your room or redesigning
        <br /> multiple builds from your room.
      </div>
      {/* <Button text="Next" onClick={() => submitRoomSize()} /> */}
      <div className="kit-room-control">
        <Button
          text="Back"
          variant="secondary"
          onClick={() => props.history.replace("/dashboard")}
        />
        <Button
          text="Continue"
          variant="primary-new"
          className={continueDisabled ? "disabled" : ""}
          onClick={() => {
            if (errorToShow) {
              props.openPopup({
                content: errorToShow.message,
              });
              return;
            }
            if (continueDisabled) {
              return;
            }

            const executeThis = () => {
              validateStepBeforeContinue();
            };

            const trayWarning = props.getOneOfTheTrayWarnings();
            if (trayWarning) {
              props.openSpacingWarning(() => {
                executeThis();
              });
            } else {
              executeThis();
            }
          }}
          disabled={roomFunctions.roomSizeError(props.roomSize)}
        />
      </div>
    </div>
  );
}
