import objectPath from "object-path";
import {
  GROW_ARE_IDS,
  PLUMBING_POSITIONS,
  PLUMBING_POSITIONS_STRING,
  PLUMBING_THICKNESS,
  STEP_VALUES,
  MAX_SQ_FT,
} from "./../../../utils/constants";

const Decimal = require("decimal.js");

function getPlatformRowDimensions(state) {
  const default_ROW_WIDTHVAL = state.platformSize;
  const default_ROW_LENGTHVAL =
    state.platformData.platformLinkLength *
      state.platformData.platformPlatformsPerRow +
    state.platformSize;

  let roomWidth = 0;
  let roomHeight = 0;

  if (
    state.drainage === "down" ||
    state.drainage === "up" ||
    state.drainage === "none" ||
    !state.drainage
  ) {
    roomWidth = default_ROW_WIDTHVAL;
    roomHeight = default_ROW_LENGTHVAL;
  } else {
    //"right" || "left"

    roomWidth = default_ROW_LENGTHVAL;
    roomHeight = default_ROW_WIDTHVAL;
  }

  return {
    roomWidth,
    roomHeight,
  };
}
export function getPlatformSize(state) {
  // TODO: Probably we should not set this variable based on item ID, maybe some other way to tell the 10 gallon pot is better
  if (state.chosenPot.id === 7) {
    return 1.6;
  }

  return 1.2;
}

/**
 * the problem with the platform is some rows are missing
 * the rest of the calculation will be handled from here in the KitPlatformLayout.js
 * @param {import("./../../../utils/constants").IKitBuilderState} state
 */
export function addRowsMissingFromDefaultZones(state) {
  const numberOfRows = state.zones.filter((item) => item.label === "ROW")
    .length;

  if (numberOfRows !== state.platformData.platformNumRows) {
    /** insert 2 by 2 */
    for (let i = numberOfRows; i < state.platformData.platformNumRows; i += 2) {
      let zonesLength = state.zones.length;
      let rows = state.zones.filter((item) => item.label === "ROW");

      let rowPrototype = rows[0];
      let newRow1 = { ...rowPrototype };
      newRow1.id = zonesLength;
      let newRow2 = { ...rowPrototype };
      newRow2.id = zonesLength + 1;

      let subzones = state.zones.filter((item) => item.label === "SUBZONE");
      let zonePrototype = subzones[0];
      let newZone = { ...zonePrototype };
      newZone.id = zonesLength + 2;

      state.zones.push(newRow1, newRow2, newZone);
    }
  }

  /** update the positon in canvas -> the platform position is calculated around the platform */
  for (let i = 0; i < state.zones.length; i++) {
    if (state.zones[i].label === "PLATFORM") {
      state.zones[i].offsetX = state.platformData.platformXcoord;
      state.zones[i].offsetY = state.platformData.platformYcoord;
      break;
    }
  }
}

export const trayErrors = {
  EXCEED_TOTAL: {
    message: {
      title: "",
      text: "Room limit exceeded.",
    },
    error_code: "EXCEED_TOTAL",
  },
  ROOM_TOO_SMALL: {
    message: {
      title: "ROOM DIMENSIONS REQUIRED",
      text:
        "Room dimensions are required to continue building. Please define your room's length and width.",
    },
    error_code: "ROOM_TOO_SMALL",
  },
  ROOM_INCORECT: {
    message: {
      title: "ROOM DIMENSIONS REQUIRED",
      text:
        "Room dimension are required to continue building. Please define your room's length and width.",
    },
    error_code: "ROOM_INCORECT",
  },
  UNABLE_TO_ROTATE: {
    message: {
      title: "",
      text: "This tray can't be rotated.",
    },
    error_code: "UNABLE_TO_ROTATE",
  },
  TRAY_OVERLAP: {
    message: {
      title: "",
      text: "Some trays are overlapping.",
    },
    error_code: "TRAY_OVERLAP",
  },
  TRAY_CAN_NOT_BE_INCREASED: {
    message: {
      title: "",
      text: "Tray dimensions can not exceed the room.",
    },
    error_code: "TRAY_CAN_NOT_BE_INCREASED",
  },
  NO_TRAY_ERROR: {
    message: {
      title: "",
      text: "Room needs to have at least one tray.",
    },
    error_code: "NO_TRAY_ERROR",
  },
  ROOM_DIMENSION_FOUR: {
    message: {
      title: "ROOM DIMENSIONS REQUIRED",
      text:
        "Room dimensions are required to continue building. Please define your room's length and width.",
    },
    error_code: "ROOM_DIMENSION_FOUR",
  },
  SELECT_AN_OPTION: {
    message: {
      title: "Irrigation Style Required",
      text:
        "An Irrigation Style is required to continue building. Please select the type of automated irrigation you would like to use.",
    },
    error_code: "SELECT_AN_OPTION",
  },
  SELECT_AN_OPTION_GROW_AREA: {
    message: {
      title: "Grow Area Required",
      text:
        "An Grow Area is required to continue building. Please select the type of grow area you would like to use.",
    },
    error_code: "SELECT_AN_OPTION_GROW_AREA",
  },
  SELECT_A_POT_OR_CONTAINER: {
    message: {
      title: "A Pot or Medium is required to continue building.",
      text: "Please select the Pot or Medium you plan to use.",
    },
    error_code: "SELECT_A_POT_OR_CONTAINER",
  },
};

export const trayWarnings = {
  TRAY_WILL_RESET: {
    message: {
      title:
        "Would you like to continue with this change or would you like to create a new build with these new dimensions?",
      text:
        "Changing your room dimensions will reset your build to the beginning and remove all your previously defined choices.",
    },
    error_code: "TRAY_WILL_RESET",
    buttonYes: "Confirm",
    buttonNo: "Cancel",
  },
  TRAY_DISTANCEING: {
    message: {
      title: "Would you like to continue with this tray placement?",
      text: "Trays are tightly packed and maybe inaccessible.",
    },
    buttonYes: "Continue",
    buttonNo: "No",
    error_code: "TRAY_DISTANCEING",
  },
  TRAY_PLUMBING_DISTANCING: {
    message: {
      title: "CONFIRM PLUMBING PLACEMENT",
      text: "",
    },
    buttonYes: "Continue",
    buttonNo: "No",
    error_code: "TRAY_PLUMBING_DISTANCING",
    nodeAttributes: {
      ["css-code"]: "TRAY_PLUMBING_DISTANCING", //for popup styling attribute
    },
  },
};

export const generalErrors = {
  NO_WATER_ZONE: {
    message: {
      title: "ZONING STYLE REQUIRED",
      text:
        "A Zoning Style is required to continue building. Please select One Zone or Sub-zone.",
    },
    error_code: "NO_WATER_ZONE",
  },
};

export const CART_WARNINGS = {
  CART_REPLACE_ROOM: {
    key: "CART_REPLACE_ROOM",
    content: {
      title: "BUILD ALREADY IN CART",
      text: (
        <>
          <p>
            You already have a Room Builder build in your cart. The FloraFlex
            <sup>®</sup> Room Builder currently only supports one build per
            order.
          </p>
          <p>
            Would you like to remove the current build from your cart and
            replace it with this build? Or, to purchase more than one build,
            please complete the checkout process of your current cart and then
            return to the Room Builder to purchase this build as a second order.
          </p>
        </>
      ),
    },
    buttonYes: "REPLACE CURRENT BUILD",
    buttonNo: "CANCEL",
  },
};

export const availableMeasurements = [
  { key: 1, value: "FT", label: "FEET" },
  { key: 2, value: "M", label: "METERS" },
];
export function mToFt(number) {
  return Number(new Decimal(Number(number) || 0).times(3.28084));
}
export function ftToM(number) {
  return Number(new Decimal(Number(number) || 0).dividedBy(3.28084));
}

export function getErrorRoomSize({ roomLength, roomWidth }) {
  if (roomLength === "" || roomWidth === "") {
    return trayErrors.ROOM_INCORECT;
  } else if (roomLength < 4 || roomWidth < 4) {
    return trayErrors.ROOM_DIMENSION_FOUR;
  }
  return undefined;
}

/**
 * room density name without the area at last
 */
export function generateDisplayDensity(room_data) {
  let densityForDisplay =
    typeof room_data?.density === "string" ? room_data.density : "";
  densityForDisplay = densityForDisplay.replace(/\sarea$/i, "");
  densityForDisplay = densityForDisplay.replace(/\spots\s/i, " plants ");
  densityForDisplay = densityForDisplay.replace(/\splant\s/i, " plants ");
  return densityForDisplay;
}

export function getTrayError({ trays, roomLength, roomWidth }) {
  const availableArea = roomLength * roomWidth;
  let trayArea = 0;
  for (let i = 0; i < trays.length; i++) {
    const item = trays[i];
    trayArea += item.lengthVal * item.widthVal;
    if (trayArea > availableArea) {
      return trayErrors.EXCEED_TOTAL;
    }
  }
  return null;
}

export function getRotateError(trayItem, roomLength, roomWidth) {
  /* TO BE REVIEWED AFTER rigth table positioning */
  if (trayItem.xPosition == true) {
    if (trayItem.lengthVal > roomWidth) {
      return trayErrors.UNABLE_TO_ROTATE;
    }
  } else {
    if (trayItem.lengthVal > roomLength) {
      return trayErrors.UNABLE_TO_ROTATE;
    }
  }
  return null;
}
function getTraySquareCoords(item, takePlumbingIntoAccount) {
  const widthVal_RBased = item.isRotated ? item.lengthVal : item.widthVal;
  const lengthVal_RBased = item.isRotated ? item.widthVal : item.lengthVal;

  let leftPlumbing = 0;
  let topPlumbing = 0;
  let bottomPlumbing = 0;
  let rightPlumbing = 0;

  if (takePlumbingIntoAccount) {
    switch (item.plumbingPosition) {
      case PLUMBING_POSITIONS.LEFT:
        leftPlumbing = PLUMBING_THICKNESS;
        break;

      case PLUMBING_POSITIONS.TOP:
        topPlumbing = PLUMBING_THICKNESS;
        break;

      case PLUMBING_POSITIONS.BOTTOM:
        bottomPlumbing = PLUMBING_THICKNESS;
        break;

      default:
        rightPlumbing = PLUMBING_THICKNESS;
        break;
    }
  }

  const mainCoords = {
    x: item.offsetX - leftPlumbing,
    y: item.offsetY - topPlumbing,
    width: widthVal_RBased + (rightPlumbing || leftPlumbing),
    height: lengthVal_RBased + (topPlumbing || bottomPlumbing),
  };

  return {
    ...mainCoords,
    ...createRectanglePoints(mainCoords),
  };
}

/**
 *
 * @param {object} mainCoords
 * @returns {object}
 */
function createRectanglePoints(mainCoords) {
  return {
    topLeft: {
      x: mainCoords.x,
      y: mainCoords.y,
    },
    topRight: {
      x: mainCoords.x + mainCoords.width,
      y: mainCoords.y,
    },
    bottomLeft: {
      x: mainCoords.x,
      y: mainCoords.y + mainCoords.height,
    },
    bottomRight: {
      x: mainCoords.x + mainCoords.width,
      y: mainCoords.y + mainCoords.height,
    },
  };
}

/**
 * gets the plumbing coords of a tray
 * @param {import("../Canvas/Models/Room").ITray} item
 * @returns {object}
 */
function getTrayPlumbingCoords(item) {
  const widthVal_RBased = item.isRotated ? item.lengthVal : item.widthVal;
  const lengthVal_RBased = item.isRotated ? item.widthVal : item.lengthVal;

  const plumbingPosition = item.plumbingPosition;

  let mainCoords = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
  switch (plumbingPosition) {
    case PLUMBING_POSITIONS.LEFT:
      mainCoords = {
        x: item.offsetX - PLUMBING_THICKNESS,
        y: item.offsetY,
        width: PLUMBING_THICKNESS,
        height: lengthVal_RBased,
      };
      break;

    case PLUMBING_POSITIONS.TOP:
      mainCoords = {
        x: item.offsetX,
        y: item.offsetY - PLUMBING_THICKNESS,
        width: widthVal_RBased,
        height: PLUMBING_POSITIONS,
      };
      break;

    case PLUMBING_POSITIONS.BOTTOM:
      mainCoords = {
        x: item.offsetX,
        y: item.offsetY + lengthVal_RBased,
        width: widthVal_RBased,
        height: PLUMBING_POSITIONS,
      };
      break;

    default:
      //right
      mainCoords = {
        x: item.offsetX + widthVal_RBased,
        y: item.offsetY,
        width: PLUMBING_THICKNESS,
        height: lengthVal_RBased,
      };
      break;
  }

  return {
    ...mainCoords,
    ...createRectanglePoints(mainCoords),
  };
}

/**
 * used when checking out if a tray or a plumbing is close too each other
 * by making the coords larger
 * @param {object} coords
 * @param {number} distance
 */
function addDistanceToCoords(mainCoords, distance) {
  mainCoords.width = mainCoords.width + distance * 2;
  mainCoords.height = mainCoords.height + distance * 2;
  mainCoords.x -= distance;
  mainCoords.y -= distance;
  return {
    ...mainCoords,
    ...createRectanglePoints(mainCoords),
  };
}

function haveIntersection(r1, r2) {
  return !(
    r2.x > r1.x + r1.width ||
    r2.x + r2.width < r1.x ||
    r2.y > r1.y + r1.height ||
    r2.y + r2.height < r1.y
  );
}
export function getErrorTrayOverlapping(trays) {
  for (let i = 0; i < trays.length; i++) {
    const subject = trays[i];
    const subjCoords = getTraySquareCoords(subject);
    const subjCoordsWithPlumbing = getTraySquareCoords(subject, true);

    for (let ii = i + 1; ii < trays.length; ii++) {
      const searchItem = trays[ii];

      //see if the tray overlaps with another tray or another tray's plumbing
      const searchCoords = getTraySquareCoords(searchItem);
      const searchCoordsWithPlumbing = getTraySquareCoords(searchItem, true);
      //console.log(searchCoords, searchItem);
      if (
        //(!plumbing can overlap each other, but not the trays!)
        haveIntersection(subjCoords, searchCoordsWithPlumbing) ||
        haveIntersection(subjCoordsWithPlumbing, searchCoords)
      ) {
        return {
          message: {
            title: `${subject.label} and ${searchItem.label} are currently overlapping.`,
            text: `Please separate ${subject.label} and ${searchItem.label} to continue your build.`,
          },
          error_code: trayErrors.TRAY_OVERLAP,
        };
      }
    }
  }
}

export function getWarningTrayDistancing(trays, roomWidth, roomLength) {
  const computeWallWarning = (subject) => {
    return {
      ...trayWarnings.TRAY_DISTANCEING,
      message: {
        ...trayWarnings.TRAY_DISTANCEING.message,
        text: (
          <>
            {subject.label} is currently closer to the room wall than FloraFlex
            <sup>®</sup> recommends (2ft). This may limit accessibility to all
            of your&nbsp;plants.
          </>
        ),
      },
    };
  };

  const warningBetweenTrays = (subject, searchItem) => ({
    ...trayWarnings.TRAY_DISTANCEING,
    message: {
      title: "Would you like to continue with this tray placement?",
      text: (
        <>
          {" "}
          {subject.label} and {searchItem.label} are currently closer than
          FloraFlex<sup>®</sup> recommends (2ft). This may limit accessibility
          to all of your&nbsp;plants.
        </>
      ),
    },
  });

  for (let i = 0; i < trays.length; i++) {
    const subject = trays[i];
    const subjCoord = getTraySquareCoords(subject);

    /**
     * check how close to the wall each tray is
     */
    if (subjCoord.topLeft.x < 2) {
      return computeWallWarning(subject);
    }
    if (subjCoord.topLeft.x + subjCoord.width > roomWidth - 2) {
      return computeWallWarning(subject);
    }
    if (subjCoord.topLeft.y < 2) {
      return computeWallWarning(subject);
    }
    if (subjCoord.topLeft.y + subjCoord.height > roomLength - 2) {
      return computeWallWarning(subject);
    }

    /**
     * check how close to the EACH OTHER each tray is
     */
    for (let ii = i + 1; ii < trays.length; ii++) {
      const searchItem = trays[ii];

      const searchCoord = getTraySquareCoords(searchItem);
      let coordXGreater;
      let coordXSmaller;
      if (searchCoord.x > subjCoord.x) {
        coordXGreater = searchCoord;
        coordXSmaller = subjCoord;
      } else {
        coordXGreater = subjCoord;
        coordXSmaller = searchCoord;
      }
      let coordYGreater;
      let coordYSmaller;
      if (searchCoord.y > subjCoord.y) {
        coordYGreater = searchCoord;
        coordYSmaller = subjCoord;
      } else {
        coordYGreater = subjCoord;
        coordYSmaller = searchCoord;
      }

      //check x axes
      const closeOnXAxes = coordXGreater.x - coordXSmaller.topRight.x < 2;
      if (closeOnXAxes) {
        //check if y overlap
        if (coordYSmaller.bottomLeft.y > coordYGreater.topLeft.y) {
          return warningBetweenTrays(subject, searchItem);
        }
      }
      //check y axes
      const closeOnYAxes = coordYGreater.y - coordYSmaller.bottomRight.y < 2;
      if (closeOnYAxes) {
        //check if x overlap
        if (coordXSmaller.bottomRight.x > coordXGreater.bottomLeft.x) {
          return warningBetweenTrays(subject, searchItem);
        }
      }
    }
  }

  const searchPlumbing2Overlapping = are2PlumbingOverlapping(trays);
  if (searchPlumbing2Overlapping) {
    return warningBetweenTrays(
      searchPlumbing2Overlapping.subject,
      searchPlumbing2Overlapping.searchItem
    );
  }
}

export function are2PlumbingOverlapping(trays) {
  for (let i = 0; i < trays.length; i++) {
    const subject = trays[i];
    const subjCoordsWithPlumbing = getTraySquareCoords(subject, true);

    for (let ii = i + 1; ii < trays.length; ii++) {
      const searchItem = trays[ii];
      const searchCoordsWithPlumbing = getTraySquareCoords(searchItem, true);

      if (
        //(!plumbing can overlap each other, but not the trays!)
        haveIntersection(subjCoordsWithPlumbing, searchCoordsWithPlumbing)
      ) {
        return {
          subject,
          searchItem,
        };
      }
    }
  }

  return false;
}

//(!plumbing can overlap each other, but not the trays!)
export function getWarningPlumbingTray(trays, roomWidth, roomLength) {
  /**
   * generic functions of warnings
   */
  const computeWarning = (subject) => {
    return {
      ...trayWarnings.TRAY_PLUMBING_DISTANCING,
      message: {
        ...trayWarnings.TRAY_PLUMBING_DISTANCING.message,
        text: (
          <>
            You have placed the plumbing/feed end of Tray {subject.label} less
            than 1ft from the room wall.
            <br></br>
            <br></br>
            This layout is possible if you plan to mount your plumbing to the
            wall directly. However, for your Room Builder build to look visually
            accurate we recommend moving the plumbing end of Tray A two feet
            away from the wall.
          </>
        ),
      },
    };
  };

  for (let i = 0; i < trays.length; i++) {
    const subject = trays[i];
    const plumbingPosition = trays[i].plumbingPosition;

    const subjCoord = getTraySquareCoords(subject);
    const offset = 2;

    /**
     * check how close plumbing is to the wall
     *
     * */
    if (
      plumbingPosition === PLUMBING_POSITIONS.LEFT &&
      subjCoord.topLeft.x < offset
    ) {
      return computeWarning(subject);
    }

    if (
      plumbingPosition === PLUMBING_POSITIONS.RIGHT &&
      subjCoord.topLeft.x + subjCoord.width > roomWidth - offset
    ) {
      return computeWarning(subject);
    }

    if (
      plumbingPosition === PLUMBING_POSITIONS.TOP &&
      subjCoord.topLeft.y < offset
    ) {
      return computeWarning(subject);
    }

    if (
      plumbingPosition === PLUMBING_POSITIONS.BOTTOM &&
      subjCoord.topLeft.y + subjCoord.height > roomLength - offset
    ) {
      return computeWarning(subject);
    }

    //check if 2 plumbing are overlapping each other
    // const searchPlumbing2Overlapping = are2PlumbingOverlapping(trays);
    // if (searchPlumbing2Overlapping) {
    //   return computeWarning(searchPlumbing2Overlapping.subject);
    // }
  }

  return undefined;
}

export function getValidatedOffset(item, roomWidth, roomLength) {
  const widthVal_RBased = item.isRotated ? item.lengthVal : item.widthVal;
  const lengthVal_RBased = item.isRotated ? item.widthVal : item.lengthVal;
  let offsetX = item.offsetX;
  let offsetY = item.offsetY;

  if (offsetY < 0) {
    offsetY = 0;
  }
  if (offsetX < 0) {
    offsetX = 0;
  }
  if (item.offsetY + lengthVal_RBased > roomLength) {
    offsetY = roomLength - lengthVal_RBased;
  }
  if (item.offsetX + widthVal_RBased > roomWidth) {
    offsetX = roomWidth - widthVal_RBased;
  }
  return {
    offsetX,
    offsetY,
  };
}

export function defaultTrayOffset(
  widthVal,
  lengthVal,
  roomLength,
  roomWidth,
  isRotated
) {
  const widthVal_RBased = isRotated ? lengthVal : widthVal;
  const lengthVal_RBased = isRotated ? widthVal : lengthVal;

  const withRest = roomWidth - widthVal_RBased;
  const offsetX = withRest / 2;

  const heightRest = roomLength - lengthVal_RBased;
  const offsetY = heightRest / 2;

  return {
    offsetX: Math.floor(offsetX * 100) / 100,
    offsetY: Math.floor(offsetY * 100) / 100,
  };
}

export function roomSizeError({ roomLength, roomWidth }) {
  return false;
}

export function roomSquareAreaError({roomLength, roomWidth}) {
  const length = parseFloat(roomLength);
  const width = parseFloat(roomWidth);

  const sqMeasurement = new Decimal(length).times(width).toNumber();
  if (sqMeasurement > MAX_SQ_FT) {
    return new Error(`
    The dimensions you provided exceeds what we're capable of at this time.
    Support for rooms larger than ${MAX_SQ_FT} sq. ft is coming soon.
    (Current value: ${sqMeasurement})`);
  }

  return false;
}

export const nutrientErrors = {
  CHANGES_LOST: {
    message: {
      title: "Are you sure?",
      text: "The following action will reset all of your values.",
    },
    buttonYes: "Confirm",
    buttonNo: "Cancel",
    error_code: "CHANGES_LOST",
  },
  WARNING_BUILD_BREAK: {
    message: {
      title: "Are you sure you want to modify YOUR cart?",
      text: (
        <>
          {" "}
          The Full Tilt<sup>&trade;</sup> Calculator calculates the exact
          nutrients needed for your grow. However, you can add or subtract as
          desired.
          <br />
          <br />
          <b>
            Please note FloraFlex<sup>&reg;</sup> can not guarantee you will
            have sufficient FloraFlex<sup>&reg;</sup> Nutrients if you edit your
            calculated amounts
          </b>
        </>
      ),
    },
    buttonYes: "Continue",
    buttonNo: "Undo",
    error_code: "WARNING_BUILD_BREAK",
  },
  WARNING_BUILD_BREAK_COMPONENT: {
    message: {
      title: "Are you sure you want to modify YOUR cart?",
      text: (
        <>
          {" "}
          The FloraFlex<sup>&reg;</sup> Room Builder is designed to calculate
          the exact components needed for your build. However, you can add or
          subtract as desired.<br></br>
          <br />
          <b>
            Please note FloraFlex<sup>&reg;</sup> can not guarantee you will
            have sufficient/correct components if you edit your calculated
            components.
          </b>
        </>
      ),
    },
    buttonYes: "Continue",
    buttonNo: "Undo",
    error_code: "WARNING_BUILD_BREAK_COMPONENT",
  },
};

export const productTypes = {
  vf: "vf",
  v1: "v1",
  v2: "v2",
  bf: "bf",
  b1: "b1",
  b2: "b2",
  ft: "ft",
};

class NutrientCalculation {
  /**
   * handles calculations for both Room builder and Standalone
   *
   * Differences between types:
   *  1. cycles number impact
   *  2. products for cart (in this.getResults)
   *
   * @param {object} props
   * @param {string} type - ROOM_BUILDER / STANDALONE
   * @param {Number} plant
   * @param {Number} volume
   * @param {Number} week
   * @param {Number} flush
   * @param {Number} addCycles
   * @param {object} products
   * @param {object} customQuantities
   * @param {boolean} ignoreQuantity
   */
  constructor(props) {
    this.productTypes = {
      vf: "vf",
      v1: "v1",
      v2: "v2",
      bf: "bf",
      b1: "b1",
      b2: "b2",
      ft: "ft",
    };
    this.appTypes = {
      ROOM_BUILDER: "ROOM_BUILDER",
      STANDALONE: "STANDALONE", //DEFAULT
    };

    this.type = props.type;

    this.plant = Number(props.plant) || 1;
    this.volume = Number(props.volume) || 1;
    this.week = Number(props.week) || 0;
    this.flush = Number(props.flush) || 1;
    /**
     * cast to number regardless (avoid NaN)
     * add defaults - 1
     */
    this.volume = Number(this.volume) || 1;
    this.plant = Number(this.plant) || 1;
    this.flush = Number(this.flush) || 1;

    /**
     * ROOM BUILDER - CYCLES (always addCycles > 0 )
     * NC - ADDITIONLA CYCLES
     */
    if (this.type === this.appTypes.ROOM_BUILDER) {
      this.addCycles = Number(props.addCycles) || 1;
      this.addCyclesCalc = this.addCycles;
      //week
      this.weekCalc = this.week;
    } else {
      this.addCycles = Number(props.addCycles) || 0;
      this.addCyclesCalc = this.addCycles + 1;
      //week
      this.weekCalc = this.week;
    }
    this.addCyclesCalc_dec = new Decimal(this.addCyclesCalc);

    this.products = props.products;
    this.customQuantities = props.customQuantities;
    this.ignoreQuantity = props.ignoreQuantity;
  }

  /**
   * handles if a property is number or (undefined|null)
   * @param {any} number
   */
  isNotNulOrUndefined = (number) => {
    return number !== null && number !== undefined;
  };

  myPaseFloat = (number) => {
    return Number(number) || 0;
  };

  /**
   * VEG STAGE
   * BLOOM STAGE
   * FLUSH STAGE
   * @returns {object}
   */
  getStageTotals = () => {
    // VEG STAGE
    const vegGallon_dec = new Decimal(this.volume)
      .times(0.15)
      .times(this.plant)
      .times(7);

    // BLOOM STAGE
    const bloomGallons_dec = new Decimal(this.volume)
      .times(0.35)
      .times(this.plant)
      .times(7);

    // FLUSH STAGE - 1 or 2 depending on button clicked
    const flushResult_dec = new Decimal(0.25)
      .times(this.volume)
      .times(this.plant)
      .times(7);

    /**
     * Total Veg Gallons PER DAY OF THE WEEK
     */
    const VeGWk1 = vegGallon_dec;
    const VeGWk2 = vegGallon_dec;
    const VeGWk3 = vegGallon_dec;
    const VeGWk4 = vegGallon_dec;
    const VeGWk5 = new Decimal(0);
    const VeGWk6 = new Decimal(0);
    const VeGWk7 = new Decimal(0);

    const totalVegGallons_dec = VeGWk1.plus(VeGWk2)
      .plus(VeGWk3)
      .plus(VeGWk4)
      .plus(VeGWk5)
      .plus(VeGWk6)
      .plus(VeGWk7);

    /**
     * Total Bloom Gallons PER DAY OF THE WEEK
     */
    const BloomGWk1 = bloomGallons_dec;
    const BloomGWk2 = bloomGallons_dec;
    const BloomGWk3 = bloomGallons_dec;
    const BloomGWk4 = bloomGallons_dec;
    const BloomGWk5 = bloomGallons_dec.plus(
      bloomGallons_dec.times(this.weekCalc)
    );
    const BloomGWk6 = bloomGallons_dec;
    const BloomGWk7 = bloomGallons_dec;

    const totalBloomGallons_dec = BloomGWk1.plus(BloomGWk2)
      .plus(BloomGWk3)
      .plus(BloomGWk4)
      .plus(BloomGWk5)
      .plus(BloomGWk6)
      .plus(BloomGWk7);

    const flushResultAdded_dec = flushResult_dec.times(this.flush);

    return {
      vegGallon_dec,
      bloomGallons_dec,
      flushResult_dec,
      flushResultAdded_dec,
      totalVegGallons_dec,
      totalBloomGallons_dec,
      /**
       * values cast to Number from Decimal
       */
      vegGallon: Number(vegGallon_dec),
      bloomGallons: Number(bloomGallons_dec),
      flushResult: Number(flushResult_dec),
      flushResultAdded: Number(flushResultAdded_dec),
      totalVegGallons: Number(totalVegGallons_dec),
      totalBloomGallons: Number(totalBloomGallons_dec),
    };
  };

  /**
   * Total Water Consumption (in Gallons per grow)
   * @returns {string}
   */
  getWaterConsumption = () => {
    const {
      flushResultAdded_dec,
      totalVegGallons_dec,
      totalBloomGallons_dec,
    } = this.getStageTotals();

    const water = Number(
      totalBloomGallons_dec.plus(totalVegGallons_dec).plus(flushResultAdded_dec)
    ).toFixed(2);

    return water;
  };

  getBagForWeight = (weight, type) => {
    let bag25,
      bag10,
      bag5,
      bag1 = 0;

    bag25 = Math.floor(weight / 25);
    weight = weight % 25;

    bag10 = Math.floor(weight / 10);
    weight = weight % 10;

    bag5 = Math.floor(weight / 5);
    weight = weight % 5;

    bag1 = Math.ceil(weight);

    /**
     * room builder nc has these bags editable
     */
    if (this.customQuantities && typeof this.customQuantities === "object") {
      return {
        bag1: this.isNotNulOrUndefined(this.customQuantities[`${type}_1lb`])
          ? this.myPaseFloat(this.customQuantities[`${type}_1lb`])
          : bag1,
        bag5: this.isNotNulOrUndefined(this.customQuantities[`${type}_5lb`])
          ? this.myPaseFloat(this.customQuantities[`${type}_5lb`])
          : bag5,
        bag10: this.isNotNulOrUndefined(this.customQuantities[`${type}_10lb`])
          ? this.myPaseFloat(this.customQuantities[`${type}_10lb`])
          : bag10,
        bag25: this.isNotNulOrUndefined(this.customQuantities[`${type}_25lb`])
          ? this.myPaseFloat(this.customQuantities[`${type}_25lb`])
          : bag25,
      };
    }
    return {
      bag1,
      bag5,
      bag10,
      bag25,
    };
  };

  /**
   * VF WEEKS CALCULATIONS
   */
  getVegFoliar = () => {
    const { vegGallon_dec } = this.getStageTotals();

    const VFWk1 = vegGallon_dec.times(4).dividedBy(7);
    const VFWk2 = vegGallon_dec.times(4).dividedBy(7);
    const VFWk3 = vegGallon_dec.times(4).dividedBy(7);
    const VFWk4 = vegGallon_dec.times(4).dividedBy(7);
    const VFWk5 = new Decimal(0);
    const VFWk6 = new Decimal(0);
    const VFWk7 = new Decimal(0);

    let totalVegFoliar = VFWk1.plus(VFWk2)
      .plus(VFWk3)
      .plus(VFWk4)
      .plus(VFWk5)
      .plus(VFWk6)
      .plus(VFWk7);
    let totalVegFoliartoLbs = Number(totalVegFoliar.dividedBy(453.59));
    let vegFoliar = totalVegFoliartoLbs.toFixed(2) * this.addCyclesCalc;

    /**
     * cast to Number from Decimal
     */
    totalVegFoliar = Number(totalVegFoliar);

    /**
     * lbs bags
     */
    const { bag1, bag5, bag10, bag25 } = this.getBagForWeight(
      Math.ceil(vegFoliar),
      this.productTypes.vf
    );

    return {
      vegFoliar,
      totalVegFoliar,
      /**
       * lbs
       */
      vf_1lb: bag1,
      vf_5lb: bag5,
      vf_10lb: bag10,
      vf_25lb: bag25,
    };
  };

  /**
   * V1 WEEKS CALCULATIONS
   */
  getV1 = () => {
    const { vegGallon_dec } = this.getStageTotals();

    const V1Wk1 = vegGallon_dec.times(2);
    const V1Wk2 = vegGallon_dec.times(2);
    const V1Wk3 = vegGallon_dec.times(2.5);
    const V1Wk4 = vegGallon_dec.times(3);
    const V1Wk5 = new Decimal(0);
    const V1Wk6 = new Decimal(0);
    const V1Wk7 = new Decimal(0);

    let totalV1 = V1Wk1.plus(V1Wk2)
      .plus(V1Wk3)
      .plus(V1Wk4)
      .plus(V1Wk5)
      .plus(V1Wk6)
      .plus(V1Wk7);

    let v1toLbs = Number(totalV1.dividedBy(453.59));

    let v1 = v1toLbs.toFixed(2) * this.addCyclesCalc;

    /**
     * cast to Number from Decimal
     */
    totalV1 = Number(totalV1);

    /**
     * lbs bags
     */
    const { bag1, bag5, bag10, bag25 } = this.getBagForWeight(
      Math.ceil(v1),
      this.productTypes.v1
    );

    return {
      v1,
      totalV1,
      /**
       * lbs
       */
      v1_1lb: bag1,
      v1_5lb: bag5,
      v1_10lb: bag10,
      v1_25lb: bag25,
    };
  };

  /**
   * V2 WEEKS CALCULATIONS
   */
  getV2 = () => {
    const { vegGallon_dec } = this.getStageTotals();

    const V2Wk1 = vegGallon_dec.times(2);
    const V2Wk2 = vegGallon_dec.times(2);
    const V2Wk3 = vegGallon_dec.times(2.5);
    const V2Wk4 = vegGallon_dec.times(3);
    const V2Wk5 = new Decimal(0);
    const V2Wk6 = new Decimal(0);
    const V2Wk7 = new Decimal(0);

    let totalV2 = V2Wk1.plus(V2Wk2)
      .plus(V2Wk3)
      .plus(V2Wk4)
      .plus(V2Wk5)
      .plus(V2Wk6)
      .plus(V2Wk7);

    let v2toLbs = totalV2.dividedBy(453.59);
    let v2 = Number(v2toLbs.times(this.addCyclesCalc_dec));

    /**
     * cast to Number from Decimal
     */
    totalV2 = Number(totalV2);

    /**
     * lbs bags
     */
    const { bag1, bag5, bag10, bag25 } = this.getBagForWeight(
      Math.ceil(v2),
      this.productTypes.v2
    );

    return {
      v2,
      totalV2,
      /**
       * lbs
       */
      v2_1lb: bag1,
      v2_5lb: bag5,
      v2_10lb: bag10,
      v2_25lb: bag25,
    };
  };

  /**
   * BLOOM FOLIAR WEEKS CALCULATIONS
   */
  getBloomFoliar = () => {
    const { bloomGallons_dec } = this.getStageTotals();

    const BFWk1 = bloomGallons_dec.times(4).dividedBy(7);
    const BFWk2 = bloomGallons_dec.times(4).dividedBy(7);
    const BFWk3 = bloomGallons_dec.times(4).dividedBy(7);
    const BFWk4 = new Decimal(0);
    const BFWk5 = new Decimal(0);
    const BFWk6 = new Decimal(0);
    const BFWk7 = new Decimal(0);

    let totalBloomFoliar = BFWk1.plus(BFWk2)
      .plus(BFWk3)
      .plus(BFWk4)
      .plus(BFWk5)
      .plus(BFWk6)
      .plus(BFWk7);

    let bloomFoliartoLbs = totalBloomFoliar.dividedBy(453.59);
    let bloomFoliar = Number(bloomFoliartoLbs.times(this.addCyclesCalc_dec));

    /**
     * cast to Number from Decimal
     */
    totalBloomFoliar = Number(totalBloomFoliar);

    /**
     * lbs bags
     */
    const { bag1, bag5, bag10, bag25 } = this.getBagForWeight(
      Math.ceil(bloomFoliar),
      this.productTypes.bf
    );

    return {
      bloomFoliar,
      totalBloomFoliar,
      /**
       * lbs
       */
      bf_1lb: bag1,
      bf_5lb: bag5,
      bf_10lb: bag10,
      bf_25lb: bag25,
    };
  };

  /**
   * BLOOM B1 WEEKS CALCULATIONS
   */
  getB1 = () => {
    const { bloomGallons_dec } = this.getStageTotals();

    const B1Wk1 = bloomGallons_dec.times(3);
    const B1Wk2 = bloomGallons_dec.times(3);
    const B1Wk3 = bloomGallons_dec.times(3);
    const B1Wk4 = bloomGallons_dec.times(3);
    const B1Wk5 = bloomGallons_dec
      .times(3)
      .plus(new Decimal(bloomGallons_dec.times(3).times(this.weekCalc)));
    const B1Wk6 = bloomGallons_dec.times(2);
    const B1Wk7 = new Decimal(0);

    let initialB1 = B1Wk1.plus(B1Wk2)
      .plus(B1Wk3)
      .plus(B1Wk4)
      .plus(B1Wk5)
      .plus(B1Wk6)
      .plus(B1Wk7);

    let b1toLbs = initialB1.dividedBy(453.59);
    let b1 = Number(b1toLbs.times(this.addCyclesCalc_dec));

    /**
     * cast to Number from Decimal
     */
    initialB1 = Number(initialB1);

    /**
     * lbs bags
     */
    const { bag1, bag5, bag10, bag25 } = this.getBagForWeight(
      Math.ceil(b1),
      this.productTypes.b1
    );

    return {
      b1,
      initialB1,
      /**
       * lbs
       */
      b1_1lb: bag1,
      b1_5lb: bag5,
      b1_10lb: bag10,
      b1_25lb: bag25,
    };
  };

  /**
   * BLOOM B2 WEEKS CALCULATIONS
   */
  getB2 = () => {
    const { bloomGallons_dec } = this.getStageTotals();

    const B2Wk1 = bloomGallons_dec.times(3);
    const B2Wk2 = bloomGallons_dec.times(3);
    const B2Wk3 = bloomGallons_dec.times(3);
    const B2Wk4 = bloomGallons_dec.times(4);
    const B2Wk5 = bloomGallons_dec
      .times(2)
      .plus(new Decimal(bloomGallons_dec.times(2).times(this.weekCalc)));
    const B2Wk6 = bloomGallons_dec.times(2);
    const B2Wk7 = new Decimal(0);

    let initialB2 = B2Wk1.plus(B2Wk2)
      .plus(B2Wk3)
      .plus(B2Wk4)
      .plus(B2Wk5)
      .plus(B2Wk6)
      .plus(B2Wk7);

    let b2toLbs = initialB2.dividedBy(453.59);
    let b2 = Number(b2toLbs.times(this.addCyclesCalc_dec));

    /**
     * cast to Number from Decimal
     */
    initialB2 = Number(initialB2);

    /**
     * lbs bags
     */
    const { bag1, bag5, bag10, bag25 } = this.getBagForWeight(
      Math.ceil(b2),
      this.productTypes.b2
    );

    return {
      b2,
      initialB2,
      /**
       * lbs
       */
      b2_1lb: bag1,
      b2_5lb: bag5,
      b2_10lb: bag10,
      b2_25lb: bag25,
    };
  };

  /**
   * BLOOM FULL TILT - WEEKS CALCULATIONS
   */
  getBloomFullTilt = () => {
    const { bloomGallons_dec } = this.getStageTotals();

    const BFTWk1 = new Decimal(0);
    const BFTWk2 = new Decimal(0);
    const BFTWk3 = new Decimal(0);
    const BFTWk4 = new Decimal(0);
    const BFTWk5 = bloomGallons_dec
      .times(3)
      .plus(new Decimal(bloomGallons_dec.times(3).times(this.weekCalc)));
    const BFTWk6 = bloomGallons_dec.times(4);
    const BFTWk7 = bloomGallons_dec.times(8);

    let initialFT = BFTWk1.plus(BFTWk2)
      .plus(BFTWk3)
      .plus(BFTWk4)
      .plus(BFTWk5)
      .plus(BFTWk6)
      .plus(BFTWk7);

    let initialFTtoLbs = initialFT.dividedBy(453.59);
    let fullTilt = Number(initialFTtoLbs.times(this.addCyclesCalc_dec));

    /**
     * cast to Number from Decimal
     */
    initialFT = Number(initialFT);

    /**
     * lbs bags
     */
    const { bag1, bag5, bag10, bag25 } = this.getBagForWeight(
      Math.ceil(fullTilt),
      this.productTypes.ft
    );

    return {
      fullTilt,
      initialFT,
      /**
       * lbs
       */
      ft_1lb: bag1,
      ft_5lb: bag5,
      ft_10lb: bag10,
      ft_25lb: bag25,
    };
  };

  /**
   * ROOM BUILDER - get stats
   */
  getResults = () => {
    const {
      vegFoliar,
      totalVegFoliar,
      vf_1lb,
      vf_5lb,
      vf_10lb,
      vf_25lb,
    } = this.getVegFoliar();
    const { v1, totalV1, v1_1lb, v1_5lb, v1_10lb, v1_25lb } = this.getV1();
    const { v2, totalV2, v2_1lb, v2_5lb, v2_10lb, v2_25lb } = this.getV2();
    const {
      bloomFoliar,
      totalBloomFoliar,
      bf_1lb,
      bf_5lb,
      bf_10lb,
      bf_25lb,
    } = this.getBloomFoliar();
    const { b1, initialB1, b1_1lb, b1_5lb, b1_10lb, b1_25lb } = this.getB1();
    const { b2, initialB2, b2_1lb, b2_5lb, b2_10lb, b2_25lb } = this.getB2();
    const {
      fullTilt,
      initialFT,
      ft_1lb,
      ft_5lb,
      ft_10lb,
      ft_25lb,
    } = this.getBloomFullTilt();

    const water = this.getWaterConsumption();

    const _allLb = {
      vf_1lb,
      vf_5lb,
      vf_10lb,
      vf_25lb,

      v1_1lb,
      v1_5lb,
      v1_10lb,
      v1_25lb,

      v2_1lb,
      v2_5lb,
      v2_10lb,
      v2_25lb,

      bf_1lb,
      bf_5lb,
      bf_10lb,
      bf_25lb,

      b1_1lb,
      b1_5lb,
      b1_10lb,
      b1_25lb,

      b2_1lb,
      b2_5lb,
      b2_10lb,
      b2_25lb,

      ft_1lb,
      ft_5lb,
      ft_10lb,
      ft_25lb,
    };

    if (this.type === this.appTypes.ROOM_BUILDER) {
      const generateCartItemsForType = (type) => {
        let cartItems = [];
        const item = this.products.find((_item) => _item.key === type);

        if (item) {
          const value_1lb = _allLb[`${type}_1lb`];
          const value_5lb = _allLb[`${type}_5lb`];
          const value_10lb = _allLb[`${type}_10lb`];
          const value_25lb = _allLb[`${type}_25lb`];

          if (value_1lb > 0 || this.ignoreQuantity) {
            cartItems.push({
              price: parseFloat(item["1lb_price"]),
              sku: item["1lb_sku"],
              quantity: value_1lb,
              type: "1lb",
            });
          }
          if (value_5lb > 0 || this.ignoreQuantity) {
            cartItems.push({
              price: parseFloat(item["5lb_price"]),
              sku: item["5lb_sku"],
              quantity: value_5lb,
              type: "5lb",
            });
          }
          if (value_10lb > 0 || this.ignoreQuantity) {
            cartItems.push({
              price: parseFloat(item["10lb_price"]),
              sku: item["10lb_sku"],
              quantity: value_10lb,
              type: "10lb",
            });
          }
          if (value_25lb > 0 || this.ignoreQuantity) {
            cartItems.push({
              price: parseFloat(item["25lb_price"]),
              sku: item["25lb_sku"],
              quantity: value_25lb,
              type: "25lb",
            });
          }
        }
        return cartItems;
      };

      const types = Object.entries(this.productTypes).map(
        ([property, value]) => value
      );

      let allTotal = 0;
      let allProductTotals = {};
      let allProductForCart = {};
      types.forEach((type) => {
        const aType = generateCartItemsForType(type);
        allProductTotals[type] =
          aType.reduce((prev, cur) => {
            return prev + cur.quantity * cur.price;
          }, 0) || 0;
        allTotal += allProductTotals[type];
        allProductForCart[type] = aType;
      });

      return {
        allTotal,
        allProductForCart,
        allProductTotals,
        addCyclesCalc: this.addCyclesCalc,

        water: water,

        vegFoliar: vegFoliar.toFixed(2),
        totalVegFoliar,

        v1: v1.toFixed(2),
        totalV1,

        v2: v2.toFixed(2),
        totalV2,

        bloomFoliar: bloomFoliar.toFixed(2),
        totalBloomFoliar,

        b1: b1.toFixed(2),
        initialB1,

        b2: b2.toFixed(2),
        initialB2,

        fullTilt: fullTilt.toFixed(2),
        initialFT,

        _allLb,
      };
    } else {
      const generateCartItemsForType = (type) => {
        let cartItems = [];
        const item = this.products.find((_item) => _item.key === type);

        if (item) {
          const value_1lb = _allLb[`${type}_1lb`];
          const value_5lb = _allLb[`${type}_5lb`];
          const value_10lb = _allLb[`${type}_10lb`];
          const value_25lb = _allLb[`${type}_25lb`];

          if (value_1lb > 0 || this.ignoreQuantity) {
            cartItems.push({
              price: parseFloat(item["1lb_price"]),
              sku: item["1lb_sku"],
              quantity: value_1lb,
            });
          }
          if (value_5lb > 0 || this.ignoreQuantity) {
            cartItems.push({
              price: parseFloat(item["5lb_price"]),
              sku: item["5lb_sku"],
              quantity: value_5lb,
            });
          }
          if (value_10lb > 0 || this.ignoreQuantity) {
            cartItems.push({
              price: parseFloat(item["10lb_price"]),
              sku: item["10lb_sku"],
              quantity: value_10lb,
            });
          }
          if (value_25lb > 0 || this.ignoreQuantity) {
            cartItems.push({
              price: parseFloat(item["25lb_price"]),
              sku: item["25lb_sku"],
              quantity: value_25lb,
            });
          }
        }

        return cartItems;
      };
      const types = Object.entries(productTypes).map(
        ([property, value]) => value
      );
      let allTotal = 0;
      let allProductTotals = {};
      let allProductForCart = {};
      types.forEach((type) => {
        const aType = generateCartItemsForType(type);
        allProductTotals[type] =
          aType.reduce((prev, cur) => {
            return prev + cur.quantity * cur.price;
          }, 0) || 0;
        allTotal += allProductTotals[type];
        allProductForCart[type] = aType;
      });

      return {
        allTotal,
        allProductForCart,
        allProductTotals,
        addCyclesCalc: this.addCyclesCalc,

        water: water,

        vegFoliar: vegFoliar.toFixed(2),
        totalVegFoliar,

        v1: v1.toFixed(2),
        totalV1,

        v2: v2.toFixed(2),
        totalV2,

        bloomFoliar: bloomFoliar.toFixed(2),
        totalBloomFoliar,

        b1: b1.toFixed(2),
        initialB1,

        b2: b2.toFixed(2),
        initialB2,

        fullTilt: fullTilt.toFixed(2),
        initialFT,

        _allLb,
      };
    }
  };
}

export function culculateNutrientResults({
  plant,
  volume,
  week,
  flush,
  addCycles,
  products,
  customQuantities,
  ignoreQuantity,
}) {
  const CalculationInstance = new NutrientCalculation({
    plant,
    volume,
    week,
    flush,
    addCycles,
    products,
    customQuantities,
    ignoreQuantity,
    type: "ROOM_BUILDER",
  });
  return CalculationInstance.getResults();
}

export function toTwoDecimals(number) {
  number = Number(number) || 0;
  return Math.floor(number * 100) / 100;
}

export function calculateQuickFill(item) {
  switch (item.name) {
    case "POTPRO™ 6\" POT":
      return Math.ceil(item.qty / 26);

    case "POTPRO™ 6\" 1 GALLON POT":
      return Math.ceil(item.qty / 13);

    case "POTPRO™ 8\" POT":
      return Math.ceil(item.qty / 14);

    case "POTPRO™ 8\" 2 GALLON POT":
      return Math.ceil(item.qty / 6);

    case "POTPRO™ 3 GALLON BUCKET":
      return Math.ceil(item.qty / 4);

    case "POTPRO™ 5 GALLON BUCKET":
      return Math.ceil(item.qty / 3);

    default:
      return 0;
  }
}

export function calculateComponentsResults({
  containers,
  irrigation_style,
  automation,
  plant,
}) {
  let componentTotal = new Decimal(0);
  const allProducts = {};
  const allProductForCart = [];
  Object.entries({ containers, irrigation_style, automation }).forEach(
    ([name, value]) => {
      const itemProducts = [];
      [...value].forEach((item) => {
        const quantity = new Decimal(Number(item.qty) || 0);
        const price = new Decimal(Number(item.unit_price) || 0);
        const label_name = item.name || "";
        componentTotal = componentTotal.plus(quantity.times(price));
        itemProducts.push({
          quantity,
          price,
          name: label_name,
        });

        allProductForCart.push({
          sku: item.sku,
          quantity: Number(quantity),
        });
      });
      allProducts[name] = itemProducts;
    }
  );

  let numOfPlants = new Decimal(Number(plant) || 0);
  const componentPerPlantPrice = toTwoDecimals(
    componentTotal.dividedBy(numOfPlants)
  );
  return {
    componentPerPlantPrice,
    componentTotalDec: componentTotal,
    componentTotal: toTwoDecimals(componentTotal),
    numOfPlantsDec: numOfPlants,
    allProducts,
    allProductForCart,
  };
}

export const defaultStateKitBuilder_localStorage = () => {
  let object = {};
  try {
    let objectAux = JSON.parse(localStorage.getItem("rb-last-room-loaded"));
    if (!objectAux || typeof objectAux !== "object") {
      throw "error";
    }
    const initialState = generateInitialState();
    Object.entries(initialState).forEach(([key]) => {
      if (
        objectAux[key] &&
        typeof initialState[key] === typeof objectAux[key]
      ) {
        object[key] = objectAux[key];
      } else {
        object[key] = initialState[key];
      }
    });
  } catch (err) {
    console.log(err);
    object = {};
  }
  return object;
};
export const defaultStateSelectedRoom_DEV = () => {
  return {};
};
export function getRooms(customer_id) {
  return fetch(
    `${window.BASE_URL}rbapi/getRooms.php?customer_id=${customer_id}`
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return new Promise((resolve, reject) => reject(response));
    })
    .then((data) => {
      if (Array.isArray(data)) {
        return new Promise((resolve, reject) => resolve(data));
      }
      return new Promise((resolve, reject) => reject(data));
    })
    .catch((err) => {
      console.log("", err);
      return new Promise((resolve, reject) => reject(err));
    });
}

const plaformInitialOffset = {
  offsetX: 4,
  offsetY: 7.6,
};

export function generateInitialState() {
  return {
    roomSize: {
      //the 2 following are used for all calculation across the app
      roomWidth: "", //always in ft
      roomLength: "", //always in ft
      measureSystem: "FT",

      //the 2 following are used for DISPLAY only
      roomLengthVall: "", // room in current measuresystem
      roomWidthVall: "", // room in current measuresystem
    },
    calculatorState: {
      flush: "1",
      plant: 1,
      volume: 2,
      plantLabel: "",
      plantItemId: "",
      week: 0, //additional weeks
      addCycles: 1, // grow cycles NOT ADDIONAL
    },
    /**
     * from getMatrix
     */
    plumbing: {},
    mainLinePipe: {},
    dripperStyle: {},
    dripperPerPlant: {},

    /* make "STANDARD TRAY" sort of autoselected by default (only for the navigation bar to know what steps to show, but don't selected completely - label is "") */
    growArea: { label: "", id: -1 },
    chosenPot: { label: "" },
    irrigationStyle: {
      label: "",
    },
    wateredZone: {},
    /**
     * canvas
     */
    trays: [],
    zones: [
      {
        id: 77,
        label: "WALKWAY",
        widthVal: 9.4,
        lengthVal: 7.4,
        boxStatus: true,
        xPosition: false,
        isRotated: false,
        offsetX: 2,
        offsetY: 5.6,
        physical: false,
      },
      {
        id: 78,
        label: "PLATFORM",
        widthVal: 5.4,
        lengthVal: 2.4,
        boxStatus: true,
        xPosition: false,
        isRotated: false,
        offsetX: plaformInitialOffset.offsetX,
        offsetY: plaformInitialOffset.offsetY,
        physical: false,
      },
      {
        id: 0,
        label: "DRAINAGE",
        widthVal: 10,
        lengthVal: 1,
        boxStatus: true,
        xPosition: false,
        isRotated: false,
        offsetX: 4,
        offsetY: 10,
        physical: false,
      },
      {
        id: 0,
        label: "FEED END/VALVE",
        widthVal: 10,
        lengthVal: 1,
        boxStatus: true,
        xPosition: false,
        isRotated: false,
        offsetX: 4,
        offsetY: 6.6,
        physical: false,
      },
      {
        id: 2,
        label: "ROW",
        widthVal: 1.2,
        lengthVal: 2.4,
        boxStatus: false,
        xPosition: false,
        isRotated: false,
        offsetX: 4,
        offsetY: 7.6,
        physical: true,
      },
      {
        id: 3,
        label: "ROW",
        widthVal: 1.2,
        lengthVal: 2.4,
        boxStatus: true,
        xPosition: false,
        isRotated: false,
        offsetX: 6.2,
        offsetY: 7.6,
        physical: true,
      },
      {
        id: 1,
        label: "SUBZONE",
        widthVal: 1,
        lengthVal: 2.4,
        boxStatus: true,
        xPosition: false,
        isRotated: false,
        offsetX: 5.2,
        offsetY: 7.6,
        physical: true,
      },
    ],
    platformData: {
      platformDrainageDirection: "none",
      /**
       * platformXcoord, platformYcoord should pe equal to zone[] where label="platform" fsr
       */
      platformXcoord: plaformInitialOffset.offsetX,
      platformYcoord: plaformInitialOffset.offsetY,

      platformNumRows: 2,
      platformPlatformsPerRow: 2,
      platformLinkLength: 1,
      platformWalkwayWidth: 2,
      platformSubzoneWidth: 1,
    },
    ignoreWarning: {},
  };
}

export function validatedRoomToEdit(roomLine) {
  if (!roomLine) {
    return {
      state: {},
      selectedRoom: {},
    };
  }

  let room_data = "";
  const initialState = JSON.parse(JSON.stringify(generateInitialState()));
  let roomSize = initialState.roomSize;
  let trays = initialState.trays;
  if (typeof roomLine.room_data === "string") {
    try {
      room_data = JSON.parse(roomLine.room_data);
    } catch (err) {
      console.log("error while parsing roomdata", err);
    }
  } else {
    room_data = roomLine.room_data;
  }
  if (room_data && typeof room_data === "object") {

    let valid = true;

    if (room_data._reactObjs) {
      /**
       * validate room size
       */
      const _reactObjs = room_data._reactObjs;
      if (_reactObjs.roomSize) {
        const numberValuesOk = ["roomWidth", "roomLength"].every((item) => {
          return (
            Number(_reactObjs.roomSize[item]) &&
            Number(_reactObjs.roomSize[item]) >= 0
          );
        });
        if (
          !numberValuesOk ||
          ["FT", "M"].indexOf(_reactObjs.roomSize.measureSystem) < 0
        ) {
          valid = false;
        } else {
          roomSize = { ...roomSize, ..._reactObjs.roomSize };
        }
      } else {
        valid = false;
      }
      /**
       * validate trays
       */
      if (_reactObjs.trays) {
        valid = _reactObjs.trays.every((tray) => {
          /**
           * check every property value (numeric, boolean, etc) of tray using an exemple
           */
          const properties = {
            label: "label",
            widthVal: "widthVal",
            lengthVal: "lengthVal",
            offsetX: "offsetX",
            offsetY: "offsetY",
            xPosition: "xPosition",
            isRotated: "isRotated",
          };
          return Object.entries({
            id: 1,
            label: "TRAY A",
            widthVal: 4,
            lengthVal: 8,
            offsetX: 8,
            offsetY: 13,
            xPosition: false,
            isRotated: true,
          }).every(([property]) => {
            if (properties[property]) {
              if (property === "label" && typeof tray[property] === "string") {
                return true;
              }
              if (
                property === properties.widthVal ||
                property === properties.lengthVal
              ) {
                return (
                  typeof tray[property] === "number" && tray[property] >= 4
                );
              }
              if (
                property === properties.offsetX ||
                property === properties.offsetY
              ) {
                return typeof tray[property] === "number";
              }
              if (
                property === properties.offsetX ||
                property === properties.offsetY
              ) {
                return typeof tray[property] === "number";
              }
              if (property === properties.xPosition) {
                return (
                  typeof tray[property] === "boolean" &&
                  properties[property] !== tray[properties.isRotated]
                );
              }
              if (property === properties.isRotated) {
                return typeof tray[property] === "boolean";
              }
              return false;
            }
            return true;
          });
        });
        if (valid) {
          /**
           * initialize the plumbing position for trays that don't didn't implement the plumbing beforehand
           */
          trays = _reactObjs.trays.map((item) => {
            /**
             * this means that the plumbing needs to be at the bottom
             * (was rotated once prior but back then plumbing position wasn't a thing)
             */
            if (!item.isRotated && item.plumbingPosition === undefined) {
              item.plumbingPosition = PLUMBING_POSITIONS.BOTTOM;
            } else {
              if (!item.plumbingPosition) {
                item.plumbingPosition = 0;
              }
            }
            return item;
          });
        }
      } else {
        valid = false;
      }
      if (valid) {
        /**
         * validate other
         */
        const otherProperties = [
          "calculatorState",
          "plumbing",
          "mainLinePipe",
          "growArea",
          "chosenPot",
          "irrigationStyle",
        ];
        valid = otherProperties.every((property) => {
          return (
            _reactObjs[property] && typeof _reactObjs[property] === "object"
          );
        });

        if (valid) {
          otherProperties.forEach((property) => {
            initialState[property] = _reactObjs[property];
          });
          /* ADD HERE PROPERTIES YOU DON'T WANT TO VALIDATE */
          initialState["dripperStyle"] = _reactObjs["dripperStyle"] || {};
          initialState["dripperPerPlant"] = _reactObjs["dripperPerPlant"] || {};
          initialState["platformData"] =
            _reactObjs["platformData"] || initialState.platformData;
          initialState["wateredZone"] =
            _reactObjs["wateredZone"] || initialState.wateredZone;

          console.log(_reactObjs.zones);
          if (Array.isArray(_reactObjs.zones)) {
            initialState["zones"] = _reactObjs["zones"];
          }

          window.disabledWarnings = _reactObjs["disabledWarnings"];
          /** marks if it needs to show the placement warnings if the trays or platform are moved */
          window.checkForCanvasChanges = true;

          addRowsMissingFromDefaultZones(initialState);
        }
      }
    }

    if (valid) {
      return {
        state: {
          ...initialState,
          roomSize,
          trays,
          roomKey: Date.now(),
        },
        selectedRoom: { ...roomLine },
        stepPath: "cart-summary",
      };
    }
  }
  return {
    state: {
      ...initialState,
      roomSize,
      trays,
      roomKey: Date.now(),
    },
    selectedRoom: { ...roomLine },
    stepPath: "room-dimensions",
  };
}

export function safeToUpperCase(value) {
  if (typeof value === "string") {
    return value.toUpperCase();
  }
  return value;
}

export function getGenContainerIndex(_props, response) {
  /**
   * find generic container index based on label...
   */
  let indexGenContainer = _props.chosenPot.index;
  const generic_containers = objectPath.get(
    response,
    `data.grow_areas.${_props.growArea.id}.generic_containers`
  );

  if (Array.isArray(generic_containers)) {
    let indexAux = generic_containers.findIndex(
      (item) =>
        safeToUpperCase(item.name) === safeToUpperCase(_props.chosenPot.label)
    );
    if (indexAux > -1) {
      indexGenContainer = indexAux;
    }
  }

  return indexGenContainer;
}

export function calculateNumOfPlantsFlow2(platformData) {
  return platformData.platformPlatformsPerRow * platformData.platformNumRows;
}

export const plumbingPositionToString = (plumbingValueInt) => {
  const plumbing =
    PLUMBING_POSITIONS_STRING[plumbingValueInt] || PLUMBING_POSITIONS.RIGHT;
  return plumbing;
};

/**
 * creates a validation function to pass to all components that needed using the state in the closure
 * @param {import("./../../../utils/constants").IKitBuilderState} state
 * @param {string} step - one of constants constants.STEP_VALUES
 * @returns {function}
 */
export const createStepValidationFunction = (state, step) => {
  /**
   * STANDARD TRAY -> prevent any action if trays are overlapping
   */
  if (Number(state.growArea.id) === GROW_ARE_IDS.STANDARD_TRAY) {
    const errorOverlapping = getErrorTrayOverlapping(state.trays);
    if (errorOverlapping) {
      return () => errorOverlapping;
    }
  }

  switch (step) {
    case STEP_VALUES.ROOM_DIMENSIONS:
      return () => {
        return getErrorRoomSize(state.roomSize);
      };

    case STEP_VALUES.GROW_AREA_STYLE:
      return () => {
        const errorOption =
          [GROW_ARE_IDS.PLATFORM_PROTO, GROW_ARE_IDS.STANDARD_TRAY].indexOf(
            Number(state.growArea.id)
          ) < 0
            ? trayErrors.SELECT_AN_OPTION_GROW_AREA
            : undefined;

        return errorOption;
      };

    case STEP_VALUES.TRAY_SIZE:
      return () =>
        state.trays.length === 0 ? trayErrors.NO_TRAY_ERROR : undefined;

    case STEP_VALUES.CONTAINER_OR_MEDIUM:
      return () => {
        const errorOption =
          state.chosenPot.id === undefined ||
          state.chosenPot.id === "" ||
          state.chosenPot.id === null
            ? trayErrors.SELECT_A_POT_OR_CONTAINER
            : undefined;

        return errorOption;
      };

    case STEP_VALUES.IRRIGATION_STYLE:
      return () => {
        const errorOption =
          state.irrigationStyle.id === undefined ||
          state.irrigationStyle.id === null ||
          state.irrigationStyle.id === ""
            ? trayErrors.SELECT_AN_OPTION
            : undefined;

        return errorOption;
      };

    case STEP_VALUES.ZONING_STYLE:
      return () => {
        return !state.wateredZone.id ? generalErrors.NO_WATER_ZONE : null;
      };

    case STEP_VALUES.RECOMMENDED_BUILDS:
      /**
       * from getMatrix based on the current selections
       * @param {Array} listPlantsPerArea
       * @param {Array} listPlumbingTrays
       * @param {Array} listMainlinePipe
       * @param {Array} listDripperStyle
       * @returns
       */
      return (
        listPlantsPerArea,
        listPlumbingTrays,
        listMainlinePipe,
        listDripperStyle
      ) => {
        const errorOptionPlant =
          state.calculatorState.plantItemId === undefined ||
          state.calculatorState.plantItemId === null ||
          state.calculatorState.plantItemId === ""
            ? {
                ...trayErrors.SELECT_AN_OPTION,
                message: {
                  title: "",
                  text: `Please select a 'PLANTS PER 4X4 AREA' option to continue.`,
                },
              }
            : undefined;
        const errorOptionPlumbing =
          state.plumbing.id === undefined ||
          state.plumbing.id === null ||
          state.plumbing.id === ""
            ? {
                ...trayErrors.SELECT_AN_OPTION,
                message: {
                  title: "",
                  text: `Please select a 'PLUMBING FOR 4X8 OR LARGER TRAYS' option to continue.`,
                },
              }
            : undefined;
        const errorOptionMainline =
          state.mainLinePipe.id === undefined ||
          state.mainLinePipe.id === null ||
          state.mainLinePipe.id === "null"
            ? {
                ...trayErrors.SELECT_AN_OPTION,
                message: {
                  title: "",
                  text: `Please select a 'MAINLINE PIPE DIAMETER' option to continue.`,
                },
              }
            : undefined;

        let setDripperStyle;
        if (!state.dripperStyle) {
          setDripperStyle = "none";
        } else {
          setDripperStyle = state.dripperStyle;
        }

        const errorOptionDripperStyle =
          setDripperStyle.id === undefined ||
          setDripperStyle.id === null ||
          setDripperStyle.id === "null"
            ? {
                ...trayErrors.SELECT_AN_OPTION,
                message: {
                  title: "",
                  text: `Please select a 'DRIPPER STYLE' option to continue.`,
                },
              }
            : undefined;

        let errorToShow = undefined;
        if (listPlantsPerArea && listPlantsPerArea.length > 0) {
          errorToShow = errorToShow || errorOptionPlant;
        }
        if (listPlumbingTrays && listPlumbingTrays.length > 0) {
          errorToShow = errorToShow || errorOptionPlumbing;
        }
        if (listMainlinePipe && listMainlinePipe.length > 0) {
          errorToShow = errorToShow || errorOptionMainline;
        }
        if (listDripperStyle && listDripperStyle.length > 0) {
          errorToShow = errorToShow || errorOptionDripperStyle;
        }

        return errorToShow;
      };
  }

  return () => undefined;
};

/**
 * verifies if a saved room needs to revive the placement warnings (a tray or platform has been moved)
 * @param {object} param0
 * @returns
 */
export const traysOrPlatformWereChanged = ({
  state,
  selectedRoom,
  initialRoomProps,
}) => {
  if (!selectedRoom?.id || !initialRoomProps) {
    return false;
  }

  /** check if grow area was changed -> that means the tray or platform were changed as well */
  if (state.growArea.id != initialRoomProps._reactObjs.growArea?.id) {
    return true;
  }

  if (state.growArea.id == GROW_ARE_IDS.STANDARD_TRAY) {
    const oldTrays = initialRoomProps._reactObjs.trays;
    const currentTrays = state.trays;

    return (
      oldTrays.length !== currentTrays.length ||
      /* at least of the old tray from the page load has been moved */
      oldTrays.some((item) => {
        const trayWithLabel = currentTrays.find(
          (cTray) => cTray.label === item.label
        );

        if (!trayWithLabel) {
          return false;
        }

        return (
          trayWithLabel.offsetX !== item.offsetX ||
          trayWithLabel.offsetY !== item.offsetY
        );
      })
    );
  }

  /** else verify if platform has been moved  */
  const oldPlatform = initialRoomProps._reactObjs.platformData;
  const currentPlatform = state.platformData;

  return (
    oldPlatform.platformXcoord !== currentPlatform.platformXcoord ||
    oldPlatform.platformYcoord !== currentPlatform.platformYcoord
  );
};

/**
 * if
 * reset 'dripper' selection when 'dripper per plant' will be changed to a un-permitted configuration for the current selection */
export const getResetDripperStyle = (props) => (
  dripperStyle,
  dripperPerPlantLabel
) => {
  if (restrictDripperStyleItem(props)(dripperStyle, dripperPerPlantLabel)) {
    return generateInitialState().dripperStyle;
  }
  return undefined;
};

/**
   *
   * if 'platform per rows' is odd, restrict those configurations:
    1.) 5 GAL POTPRO BUCKET -> 2 DRIPPER PER PLANT -> 4 WAY
    2.) 3 GAL POTPRO BUCKET -> 4 WAY
    3.) 2 GAL QUICK-FILL -> 4 WAY
   */
/**
 *
 * @param {object} item - 'dripper style'
 * @param {string} perPlantLabel -  'dripper per plant' label
 * @returns
 */
export const restrictDripperStyleItem = (props) => (item, perPlantLabel) => {
  const wayRegex = /4 WAY/i;

  if (
    props.growAreaId === GROW_ARE_IDS.PLATFORM_PROTO &&
    props.platformPerRows % 2 === 1 &&
    typeof props.chosenPot.label === "string" &&
    typeof item.label === "string" &&
    wayRegex.test(item.label)
  ) {
    const gal5Regex = /5\sGAL\sPOTPRO\sBUCKET/i;
    const gal3Regex = /3\sGAL\sPOTPRO\sBUCKET/i;
    const gal2Rgex = /2\sGAL\sQUICKFILL/i;

    const potLabel = props.chosenPot.label;

    if (gal2Rgex.test(potLabel) || gal3Regex.test(potLabel)) {
      return true;
    }

    if (typeof perPlantLabel === "string") {
      return (
        gal5Regex.test(potLabel) && /2 DRIPPER PER PLANT/i.test(perPlantLabel)
      );
    }
  }

  return false;
};
