import React, { useEffect, useLayoutEffect, useState } from "react";

import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Layout from "./layout/Layout";
import BasePage from "./Basepage";
import AuthPage from "./auth/AuthPage";
import { connect, useSelector } from "react-redux";
import {
  onScreenShoot,
  onDOMContentLoaded,
  isLocalhost,
  triggerCustomEvent,
} from "./utils/utilFunctions";
import PurchaseKits from "./pages/PurchaseKits/PurchaseKits";
import { Popup } from "./layout/components/Popup/Popup";
import { Component } from "react";
import { ApolloConsumer } from "@apollo/client";
import { layoutActions } from "./redux/actionFunctions/layoutActions";
import {
  authenticaionActions,
  logUserWithToken,
} from "./redux/actionFunctions/authenticationActions";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "@apollo/client/link/context";
import AppContext from "./AppContext";
import WelcomePopUp from './pages/Dashboard/WelcomePopup';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedConfigured: true,
    };

    this.originalUrlRequested = window.location.href;

    this.initGetParams();
  }

  initGetParams = () => {
    /**
     * use GET params from react router (hash) or window location
     */
    const urlParamsHash = new URL(
      `https://dummy-url.ro/${this.props.history.location.search}`
    ).searchParams;

    const urlParams = new URL(window.location.href).searchParams;

    if (!urlParamsHash.get("share_code") && !urlParamsHash.get("room_id")) {
      this.urlParamsOriginal = urlParams;
    }
    this.share_code = urlParamsHash.get("share_code");
    window.getshared = () => this.share_code;
  };

  onKeyDown = (event) => {
    onScreenShoot(event, "keydown");
  };
  componentDidMount = () => {
    // if(this.props.isAuth) {
    // props.checkToken(this.props.client, this.props.user).then(() => {
    this.checkLoggedIn();
    // }).catch(() => {})
    // }
    window.addEventListener("keyup", onScreenShoot);
    // window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("DOMContentLoaded", onDOMContentLoaded);
    if (this.props.isAuth) {
      this.welcomePopup();
    }
  };

  welcomePopup = () => {
    this.props.pushPopupStack({
      key: "WELCOME_POPUP",
      content: {
        title: "",
        text: "",
        node: (
          <WelcomePopUp
            onClose={() => {
              this.props.popPopupStack({
                key: "WELCOME_POPUP",
              });
            }}
          />
        ),
      },
    });
  }

  checkLoggedIn = () => {
    window.$.ajax({
      url: `getToken.php`,
      type: "GET",
      data: {},
      dataType: "json",
      success: (data) => {
        this.setState({ loggedConfigured: true });

        if (data.isLogged) {
          if (this.props.user?.token !== data.token) {
            this.props.logUserWithToken(
              {
                refreshToken: this.props.refreshToken,
                user: this.props.user,
                client: this.props.client,
              },
              data.token,
              this.originalUrlRequested
            );
          } else {
            triggerCustomEvent("refresh-logged-client-data");
          }
        } else {
          if (!isLocalhost() && this.props.user?.token) {
            this.props.logout(this.props.client);
          } else {
            triggerCustomEvent("refresh-logged-client-data");
          }
        }
      },
      error: (request, error) => {
        triggerCustomEvent("refresh-logged-client-data");

        console.log({ request, error });
      },
    });
  };

  handleOriginalGet = () => {
    if (this.share_code) {
      this.props.history.replace({
        pathname: "/roombuilder",
        search: `?share_code=${this.share_code}`,
      });
      this.setState({
        basePagaKey: Date.now() + "basePage",
      });
      this.share_code = null;
    }
  };

  resetOriginalParams = () => {
    this.urlParamsOriginal = null;
  };

  render() {
    return this.state.loggedConfigured ? (
      <>
        <Popup />
        {!this.props.isAuth ? (
          <Switch>
            <Route path="/purchase-kits" component={PurchaseKits} />
            <Route
              path="/auth"
              render={(routerProps) => <AuthPage {...routerProps} />}
            />
            <Route
              path="/"
              render={(props) => {
                props.history.replace({
                  pathname: "/auth",
                  search: props.history.location.search,
                });
                return "";
              }}
            />
          </Switch>
        ) : (
          (() => {
            /** apply share code ONCE */
            if (this.share_code) {
              this.handleOriginalGet();
              this.share_code = null;
              return "";
            }

            return (
              <Layout>
                <BasePage
                  key={this.state.basePagaKey}
                  resetOriginalParams={this.resetOriginalParams}
                />
              </Layout>
            );
          })()
        )}
      </>
    ) : (
      ""
    );
  }
}

const mapStateToProps = ({ auth, user }) => ({
  isAuth: (auth?.user?.token && !auth.delayLogin) || auth.delayLogout,
  user: auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  login: (authClient) =>
    authenticaionActions(dispatch).login({
      ...authClient,
    }),
  logout: (client) => authenticaionActions(dispatch).logout(client),
  checkToken: (client, user) =>
    authenticaionActions(dispatch).checkToken(client, user),

  logUserWithToken: (props, authToken, originalUrlRequested) => {
    return logUserWithToken(dispatch, props)(
      authToken,
      props.user,
      originalUrlRequested,
      props.client
    );
  },
  pushPopupStack: layoutActions(dispatch).pushPopupStack,
  popPopupStack: layoutActions(dispatch).popPopupStack,
});

const RoutesClient = (props) => {
  const [state, setState] = useState({ updateKey: 0 });

  const httpLink = createHttpLink({
    uri: `${window.BASE_URL}graphql`,
    credentials: "same-origin",
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("clientToken");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <AppContext.Provider
        value={{
          updateKey: state.updateKey,
          updateClientHeaders: (auth) => {
            setState({
              ...state,
              updateKey: Date.now(),
            });
          },
        }}
      >
        <Routes
          {...props}
          client={client}
          refreshToken={() => {
            setState({ updateKey: Date.now() });
          }}
        />
      </AppContext.Provider>
    </ApolloProvider>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RoutesClient));
